.contact-us-root {
  background: black;
  overflow: hidden;
  position: relative;
  margin-top: -10%;
  img {
    width: 100%;
    height: 80vh;
    filter: brightness(0.4);
    position: relative;
  }
  .contact-us-page-root-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .contact-us-page-bullets-container {
      padding-left: 5%;
      padding-bottom: 5%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 30%;
      margin-top: 1%;
      .contact-us-page-icon-container {
        color: white;
        display: flex;
        align-items: center;
        margin-top: 10%;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    .contact-us-page-enquiry-container {
      width: 60%;
      justify-content: center;
      .contact-us-page-enquiry-input {
        color: white;
        background-color: rgb(119 119 119 / 19%);
        margin: 1%;
        height: 40px;
        width: 40%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .contact-us-root {
    margin-top: -80%;
    img {
      width: 100%;
      height: 70vh;
      filter: brightness(0.4);
      position: relative;
    }
    .contact-us-page-root-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      align-items: center;
      .contact-us-page-bullets-container {
        width: 100%;
        h2 {
          font-size: 1.2rem;
        }
        p {
          margin: 0;
          font-size: 0.9rems;
        }
        .contact-us-page-icon-container {
          color: white;
          display: flex;
          align-items: center;
          margin-top: 2%;
          font-size: 1rem;
          cursor: pointer;
        }
      }
      .contact-us-page-enquiry-container {
        width: 100%;
        justify-content: center;
        h2 {
          font-size: 1.2rem;
        }
        .contact-us-page-enquiry-input {
          color: white;
          background-color: rgb(119 119 119 / 19%);
          margin: 1%;
          height: 40px;
          width: 40%;
          font-size: 1rem;
        }
      }
    }
  }
}
