header {
  background: #333333ad;
  z-index: 9999;
}
.icon-first-header-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 2%;
  padding-top: 1%;
  color: whitesmoke;
  cursor: pointer;
}

.select-language {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 2%;
  margin-top: 1%;
  font-size: 0.9rem;
  border: none;
  text-align: center;

  width: 40px;
  color: whitesmoke;
  cursor: pointer;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .icon-first-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
    color: whitesmoke;
    height: 40px;
    cursor: pointer;
    svg {
      font-size: 0.9rem;
    }
    a {
      font-size: 0.5rem;
    }
    text {
      display: none;
    }
    .rate-your-stay {
      display: block;
    }
  }
  .navbar-img {
    height: 60px;
  }
  .select-language {
    appearance: none;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2%;
    font-size: 0.6rem;
    width: 40px;
    border: none;
    color: whitesmoke;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    // width: 30px;
    // color: white !important;
    // font-size: 0.6rem;
    // appearance: none;
    // background: black;
    // height: 30px;
    // padding: 1%;
    // text-transform: capitalize;
    // justify-content: center;
    .option-language {
      color: #ed6c02;
      display: flex;

      background: black;
      font-weight: bold;
    }
  }
}
