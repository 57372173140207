footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: static;
    bottom: 0;
    width: 100%;
}
@media only screen and (max-width: 767px) {
    footer {
        font-size: 0.8rem;
    }
}