.rooms-page-root {
  background: black;
  overflow: hidden;
  position: relative;
  margin-top: -20%;
}
.rooms-page-img {
  width: 100%;
  height: 100vh;
  filter: brightness(0.4);
  position: relative;
}
.rooms-page-title {
  color: white;
  text-align: center;
}
.flip-box-rooms-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.3s;
  transform-style: preserve-3d;
}
.flip-box-rooms-container {
  background-color: transparent;
  width: 600px;
  height: 450px;
  perspective: 1000px;
}
.flip-box-rooms-container:hover .flip-box-rooms-inner {
  transform: rotateY(180deg);
}
.flip-box-rooms-container-1 {
  background-color: transparent;
  width: 550px;
  height: 400px;
  perspective: 1000px;
}
.flip-box-rooms-container-1:hover .flip-box-rooms-inner {
  transform: rotateY(180deg);
}
.flip-box-rooms-front,
.flip-box-rooms-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-box-rooms-back {
  transform: rotateY(180deg);
}
.rooms-page-img-1 {
  right: 5%;
  position: absolute;
  top: 1%;
  width: 550px;
  height: 400px;
}
.rooms-page-img-2 {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 550px;
  height: 400px;
}

.rooms-page-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 2%;
}

.rooms-page-first-room {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
.rooms-page-text-info-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .rooms-page-learn-more-button {
    text-align: center;
    height: 10%;
    width: 30%;
    margin-top: 5%;
  }
}
.rooms-page-second-room-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  margin-top: 5%;
  padding-bottom: 5%;
}
@media only screen and (max-width: 767px) {
  .rooms-page-root {
    margin-top: -80%;
  }
  .rooms-page-img {
    width: 100%;
    height: 70vh;
    filter: brightness(0.4);
    position: relative;
  }
  .rooms-page-title {
    color: white;
    text-align: center;
    font-size: 1.4rem;
  }
  // NoW
  .flip-box-rooms-inner {
    position: relative;
    width: 90%;
    height: 90%;
    margin: 0 auto;
    text-align: center;
    transition: transform 0.3s;
    transform-style: preserve-3d;
  }
  .flip-box-rooms-container {
    background-color: transparent;
    width: 600px;
    height: 450px;
    perspective: 1000px;
  }
  .flip-box-rooms-container:focus .flip-box-rooms-inner {
    margin: 0 auto;
    transform: rotateY(180deg);
  }
  .flip-box-rooms-container-1 {
    background-color: transparent;
    width: 100%;
    height: 400px;
    perspective: 500px;
  }
  .flip-box-rooms-container-1:focus .flip-box-rooms-inner {
    transform: rotateY(180deg);
    margin: 0 auto;
  }
  .flip-box-rooms-front,
  .flip-box-rooms-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  .flip-box-rooms-back {
    transform: rotateY(180deg);
  }
  .rooms-page-img-1 {
    right: 0%;
    position: absolute;
    top: 0%;
    width: 100%;
    height: 400px;
  }
  .rooms-page-img-2 {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 400px;
  }
  //Now
  .rooms-page-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 2%;
  }
  .rooms-page-first-room {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  .rooms-page-text-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5%;
    h2 {
      margin-top: 1%;
      font-size: 1.1rem;
    }
    span {
      font-size: 0.9rem;
      width: 90%;
    }
    .rooms-page-learn-more-button {
      text-align: center;
      height: 5%;
      width: 35%;
      font-size: 0.6rem;
      margin-top: 2%;
    }
  }
  .rooms-page-second-room-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    margin-top: 10%;
    padding-bottom: 5%;
  }
}
