.single-room-page-root {
  background: black;
  overflow: hidden;
  position: relative;
  margin-top: -20%;
}
.single-room-image-container {
  background: url("../../../public/2.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  filter: brightness(0.4);
}
.single-room-back-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.room-to-display {
  width: 300px;
  height: 300px;
  cursor: pointer;
}

li::marker {
  font-size: 1.5em;
  color: #ed6c02;
}

.room-name {
  color: whitesmoke;
  text-align: center;
}
.room-slider-and-info-container {
  display: flex;
  flex-direction: row;
  padding: 4%;
  padding-top: 2%;
}

.room-slider-container {
  width: 50%;
}
.room-first-slider-container {
  width: 80%;
  img {
    width: 500px;
    height: 500px;
  }
}
.room-second-slider-container {
  width: 80%;
  img {
    width: 100px;
    height: 100px;
  }
}
.room-info-container {
  margin: 5% auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  span {
    font-size: 1.1rem;
    color: white;
  }
  p {
    color: white;
    font-size: 1rem;
  }
  a {
    text-decoration: none;
    color: #ed6c02;
  }
  .room-info-ul {
    font-size: 1rem;
    color: white;
  }
}
.room-info-button {
  width: 30%;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 767px) {
  .single-room-page-root {
    margin-top: -80%;
    .single-room-back-button {
      font-size: 0.6rem;
    }
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .single-room-image-container {
    height: 70vh;
  }
  .room-name {
    margin-top: 1%;
    font-size: 1.2rem;
  }
  .room-slider-and-info-container {
    display: flex;
    flex-direction: column;
    padding: 4%;
    padding-top: 2%;
  }
  .room-slider-container {
    width: 90%;
    margin: 0 auto;
  }
  .room-first-slider-container {
    width: 100%;
    img {
      width: 300px;
      height: 300px;
    }
  }
  .room-second-slider-container {
    width: 100%;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .room-info-container {
    margin: 5% auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    span {
      color: white;
      font-size: 0.9rem;
      padding: 2%;
    }
    .room-info-ul {
      font-size: 0.9rem;
      color: white;
    }
    p {
      color: white;
      font-size: 0.7rem;
    }
    .room-info-a {
      color: #ed6c02;
      text-decoration: none;
    }
  }
  .room-info-button {
    width: 35%;
    font-size: 0.6rem !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
