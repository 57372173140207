.row-extras-root {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.row-extras-single-container {
  box-shadow: 0px 10px 50px rgb(148 148 148 / 31%);
  border-radius: 20%;
  padding: 4%;
  transition: transform 1s;
  z-index: 999999;
  margin-top: 7%;
  width: 15%;
  cursor: pointer;
  h2 {
    color: whitesmoke;
    text-shadow: 10px 10px 20px 20px;
  }
  p {
    color: rgba(245, 245, 245, 0.73);
  }
  svg {
    width: 50px;
    height: 50px;
  }
  text {
    color: rgba(245, 245, 245, 0.952);
    font-size: 0.9rem;
  }
}

.row-extras-single-container:hover {
  transform: scale(1.1);
  border: 0.2px solid gray;
}

@media only screen and (max-width: 767px) {
  .row-extras-root {
    margin-top: 14%;

    .row-extras-single-container {
      box-shadow: 0px 10px 50px rgb(148 148 148 / 31%);
      border-radius: 10%;
      padding: 4%;
      transition: transform 1s;
      z-index: 999999;
      margin-top: 0%;
      margin-bottom: 5%;
      cursor: pointer;
      h2 {
        color: whitesmoke;
        font-size: 0.8rem;
        text-shadow: 10px 10px 20px 20px;
      }
      p {
        font-size: 0.7rem;

        color: rgba(245, 245, 245, 0.73);
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
