@keyframes pulse {

  0%,
  100% {
    background-color: #6c6b6b75;
  }

  50% {
    background-color: black;
  }
}

.home-page-root {
  background: black;
  overflow: hidden;
  position: relative;
  margin-top: -20%;

  .carusel-image {
    width: 100%;
    height: 120vh;

    img {
      width: 100%;
      height: 120vh;
    }
  }

  .grid-container-top {
    height: 50vh;
  }

  .grid-container-bottom {
    height: 50vh;
    margin-top: 1%;
  }

  .grid-box {
    height: 100%;
    width: 100%;
    position: relative;

    .grid-box-image-top-left {
      width: 100%;
      height: 50vh;
      padding: 2%;
    }

    .grid-box-image-bottom-left {
      width: 100%;
      height: 50vh;
      padding: 2%;
    }

    .grid-box-image-top-right {
      width: 100%;
      height: 50vh;
      padding: 1%;
    }

    .grid-box-image-bottom-right {
      width: 100%;
      height: 50vh;
      padding: 2%;
    }
  }

  .arrow-go-down {
    animation: pulse 2s infinite;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background: #6c6b6b75;
    border-radius: 50%;
    cursor: pointer;
  }

  span {
    color: white;
    position: absolute;
    top: 33%;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .home-page-rooms {
    margin-top: 2%;

    .grid-content {
      width: 100%;
      height: 50vh;
      padding: 2%;
      top: 0;
      font-size: 3rem;
      color: #ed6c02;
      left: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.56);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      opacity: 0;
      transition: 0.6s;
    }

    .grid-content-bottom {
      width: 100%;
      height: 50vh;
      padding: 2%;
      top: 0;
      font-size: 3rem;
      color: #ed6c02;
      left: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.56);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      opacity: 0;
      transition: 0.6s;
    }

    .grid-content:hover {
      opacity: 1;
    }

    .grid-content-bottom:hover {
      opacity: 1;
    }

    .rooms-prices-details-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: left;

      p {
        margin-right: 10%;
        align-self: flex-end;
      }
    }
  }

  .home-page-introduction {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 5%;
    width: 100%;
    padding-bottom: 3%;

    .home-page-introduction-left-side {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 2%;
      width: 30%;

      h4 {
        color: white;
        margin-bottom: 2%;
      }

      h1 {
        color: white;
        margin-top: 2%;
        margin-bottom: 2%;
      }

      p {
        color: white;
        margin: 0;
        letter-spacing: 1px;
      }
    }

    .home-page-introduction-right-side {
      position: relative;
      display: flex;
      justify-content: center;
      width: 70%;

      .flip-box-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.3s;
        transform-style: preserve-3d;
      }

      .flip-box-container {
        background-color: transparent;
        width: 600px;
        height: 450px;
        perspective: 1000px;
      }

      .flip-box-container:hover .flip-box-inner {
        transform: rotateY(180deg);
      }

      .flip-box-container-1 {
        background-color: transparent;
        width: 300px;
        height: 450px;
        perspective: 1000px;
      }

      .flip-box-container-1:hover .flip-box-inner {
        transform: rotateY(180deg);
      }

      .flip-box-front,
      .flip-box-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        /* Safari */
        backface-visibility: hidden;
      }

      .flip-box-back {
        transform: rotateY(180deg);
      }

      .home-page-introduction-right-side-img-1 {
        right: 5%;
        position: absolute;
        top: 1%;
        width: 300px;
        height: 450px;
      }

      .home-page-introduction-right-side-img-2 {
        position: absolute;
        left: 10%;
        top: 0%;
        width: 550px;
        height: 450px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home-page-root {
    margin-top: -40%;

    .carusel-image {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 50vh;
      }
    }

    span {
      top: 20%;
      font-size: 0.7rem;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .arrow-go-down {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 18%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      background: #6c6b6b75;
      border-radius: 50%;
      cursor: pointer;
    }

    .grid-container-top {
      height: 20vh;
    }

    .grid-container-bottom {
      height: 10vh;
      margin-top: 1%;
    }

    .grid-box {
      height: 100%;
      width: 100%;
      position: relative;

      .grid-box-image-top-left {
        width: 100%;
        height: 20vh;
        padding: 2%;
      }

      .grid-box-image-top-right {
        width: 100%;
        height: 20vh;
        padding: 1%;
      }

      .grid-content {
        width: 100%;
        height: 20vh;
        padding: 2%;
        top: 0;
        font-size: 1rem;
        color: #ed6c02;
        left: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.56);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        transition: 0.6s;
      }

      .grid-content-bottom {
        width: 100%;
        height: 10vh;
        padding: 2%;
        top: 0;
        font-size: 1rem;
        color: #ed6c02;
        left: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.56);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        transition: 0.6s;
      }

      .grid-box-image-bottom-left {
        width: 100%;
        height: 10vh;
        padding: 2%;
      }

      .grid-box-image-bottom-right {
        width: 100%;
        height: 10vh;
        padding: 2%;
      }
    }

    .home-page-introduction {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .home-page-introduction-left-side {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 5%;
        width: 90%;
        margin: 0 auto;

        h1 {
          font-size: 1.2rem;
        }

        p {
          font-size: 0.9rem;
          letter-spacing: 1px;
        }
      }

      .home-page-introduction-right-side {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        // start
        .flip-box-inner {
          position: relative;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: transform 0.3s;
          transform-style: preserve-3d;
        }

        .flip-box-container {
          background-color: transparent;
          width: 90%;
          height: 300px;
          perspective: 1000px;
          margin: 0 auto;
        }

        .flip-box-container:hover .flip-box-inner {
          transform: rotateY(180deg);
        }

        .flip-box-container-1 {
          background-color: transparent;
          width: 90%;
          height: 400px;
          perspective: 1000px;
          margin: 0 auto;
          margin-bottom: 4%;
        }

        .flip-box-container-1:hover .flip-box-inner {
          transform: rotateY(180deg);
        }

        .flip-box-front,
        .flip-box-back {
          position: absolute;
          width: 100%;
          height: 100%;
          -webkit-backface-visibility: hidden;
          /* Safari */
          backface-visibility: hidden;
        }

        .flip-box-back {
          transform: rotateY(180deg);
        }

        .home-page-introduction-right-side-img-1 {
          right: 0%;
          position: absolute;
          top: 0%;
          width: 100%;
          height: 400px;
        }

        .home-page-introduction-right-side-img-2 {
          position: absolute;
          left: 0%;
          top: 0%;
          width: 100%;
          height: 300px;
        }
      }
    }
  }

  .slider {
    .slick-slide {
      height: 50vh;
    }
  }
}